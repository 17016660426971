// import React from "react";

// export const Services = (props) => {
//   return (
//     <div id="services" className="text-center">
//       <div className="container">
//         <div className="section-title">
//           <h2>Our Services</h2>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
//             dapibus leonec.
//           </p>
//         </div>
//         <div className="row">
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.name}-${i}`} className="col-md-4">
//                   {" "}
//                   <img className={d.image}></img>
//                   <div className="service-desc">
//                     <h3>{d.name}</h3>
//                     <p>{d.text}</p>
//                   </div>
//                 </div>
//               ))
//             : "loading"}
//         </div>
//       </div>
//     </div>
//   );
// };
// import React from "react";

// export const Services = (props) => {
//   return (
//     <div id="services" className="text-center">
//       <div className="container">
//         <div className="section-title">
//           <h2>Our Services</h2>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
//             dapibus leonec.
//           </p>
//         </div>
//         <div className="row">
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.name}-${i}`} className="col-md-4">
//                   <img
//                     src={d.image} // Correctly using the src attribute
//                     alt={d.name} // Adding an alt attribute
//                     className="img-responsive" // Ensuring the image is responsive
//                     style={{
//                       width: "100%", // Ensures the image fits within the column
//                       height: "auto", // Maintains aspect ratio
//                       borderRadius: "10px", // Optional: adds rounded corners
//                     }}
//                   />
//                   <div className="service-desc">
//                     <h3>{d.name}</h3>
//                     <p>{d.text}</p>
//                   </div>
//                 </div>
//               ))
//             : "Loading..."}
//         </div>
//       </div>
//     </div>
//   );
// };
import React, { useState} from "react";

export const Services = (props) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
           Our futured services
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <img
                    src={d.image} // Correctly using the src attribute
                    alt={d.name} // Adding an alt attribute
                    className="img-responsive" // Ensuring the image is responsive
                    style={{
                      width: "95%", // Fixed width (adjust as necessary)
                      height: "300px", // Set a fixed height for all images
                      borderRadius: "10px", // Rounded corners
                      margin: "0 auto", // Center the image horizontally
                      objectFit: "cover", // Ensure images cover the area while maintaining aspect ratio
                      transition: "transform 0.3s ease", // Smooth transition
                      transform:
                        hoveredIndex === i ? "scale(1.05)" : "scale(1)",
                    }}
                    onMouseEnter={() => setHoveredIndex(i)} // Set the index on mouse enter
                    onMouseLeave={() => setHoveredIndex(null)} // Reset on mouse leave
                  />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};


