import React, { useState } from "react";

export const Features = (props) => {
  // State to track which image is hovered
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data?.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-xs-6 col-md-3 text-center"
                >
                  <img
                    src={d.image}
                    alt={d.title}
                    className="img-responsive"
                    style={{
                      borderRadius: "10px",
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      margin: "auto",
                      transition: "transform 0.3s ease", // Smooth transition
                      transform:
                        hoveredIndex === i ? "scale(1.05)" : "scale(1)", // Scale on hover
                    }}
                    onMouseEnter={() => setHoveredIndex(i)} // Set the index on mouse enter
                    onMouseLeave={() => setHoveredIndex(null)} // Reset on mouse leave
                  />
                  <h3>{d.title}</h3>
                  <p style={{ marginBottom: "50px" }}>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
